const buttonElements = document.querySelectorAll('.skip-link, button, [role="button"], [role=button], [aria-role="button"], [aria-role=button], [aria-haspopup="true"]');
if(buttonElements) {
  buttonElements.forEach(function(el, i) {
    el.addEventListener('keydown', function(event) {
        if (event.key == " " ||
            event.code == "Space" ||
            event.keyCode == 32
        ) {
            event.preventDefault();
            event.stopPropagation();
            el.click();
        }
    });
  });
}

function setAllAriaHidden(searchable, selector) {
    const elements = searchable.querySelectorAll(selector);
    if(elements) {
        elements.forEach(function(el, index) {
            el.setAttribute('aria-hidden', 'true');
        });
    }
}
setTimeout(function() {
    const splides = document.querySelectorAll('.kt-post-grid-layout-carousel-wrap');
    if(splides) {
        splides.forEach(function(splide, index) {
          setAllAriaHidden(splide, '.splide__arrow svg');
          // re-init splide to prevent non-init issues
          if ( splide.matches(' .is-initialized:has(.alert)' ) ) {
            let arrows = false
            if ( splide.querySelectorAll('.alert').length > 1 ) {
              arrows = true
            }
            const newSplide = new window.Splide(splide, {pagination: false, arrows})
            newSplide.mount()
          }
        });
    }
}, 200);

const blocksyMenuToggles = document.querySelectorAll('.ct-toggle-dropdown-desktop');
if(blocksyMenuToggles) {
    blocksyMenuToggles.forEach(function(el, i) {
        const icons = el.querySelectorAll('.ct-icon');
        if(icons) {
            icons.forEach(function(icon, index) {
                icon.setAttribute('aria-hidden', 'true');
            });
        }
    });
}
/**
 * Fix top-level nav items with no sub-menu from getting aria-expanded set on hover
*/
const blocksyHeader = document.querySelector('#header');
if(blocksyHeader) {
    const observer = new MutationObserver( (mutationList) => {
        for(var mutation of mutationList) {
            if(
                mutation.type === "childList" &&
                mutation.addedNodes.length &&
                mutation.addedNodes[0].classList &&
                mutation.addedNodes[0].classList.contains('more-items-container')
            ) {
                const moreContainer = mutation.addedNodes[0];
                const moreContainerIcon = moreContainer.querySelector('.ct-toggle-dropdown-desktop svg');
                if(moreContainer && moreContainerIcon) {
                    moreContainerIcon.setAttribute('aria-hidden', true);
                    observer.disconnect();
                }
            }
        }
    } );
    observer.observe( blocksyHeader, { attributes: true, subtree: true, childList: true } );
}

const blocksyMegaMenuSubMenus = document.querySelectorAll('[data-device="desktop"] [class*="ct-mega-menu"] .sub-menu [aria-expanded]');
for(submenu of blocksyMegaMenuSubMenus) {
    submenu.removeAttribute('aria-haspopup');
    submenu.removeAttribute('aria-expanded');
}

const shareLinks = document.querySelectorAll('.ct-share-box > div > a');
shareLinks?.forEach(function(el, i) {
    if(el.hasAttribute('aria-label')) {
        let label = el.getAttribute('aria-label');
        el.setAttribute('aria-label', 'Share with ' + label);
    } 
});
